import Newsletters from "../pages/Newsletters";
import axios from "../services/axios";
import { getUserLoggedIn } from "../services/auth";

import {
  INewsletter,
  INqaDocuments,
  IMentorIngPayload,
  IBranding,
  IServiceUpdate,
  IFOMDocument,
  IService,
  IServiceBrandingPayload,
  IReport,
  IAuthUser,
  DocUpload,
  DocUpdate,
} from "../types";

import { userServicesAudit } from "../helpers/userServicesAudit";

export async function fetchNewsletters(): Promise<INewsletter[]> {
  const response = await axios.get(`/api/newsletter-get-all`);
  return response.data;
}

export async function fetchLinks() {
  const response = await axios.get(`/api/link-get-all`);
  return response.data;
}

export async function fetchNqaDocuments(): Promise<INqaDocuments[]> {
  const response = await axios.get(`/api/document-get-all`);
  console.log("response1 ", response);
  // const response1 = await axios.get(`/api/document-webinar-get-all`);

  const docs: INqaDocuments[] = response.data.sort(
    (a: INqaDocuments, b: INqaDocuments) => {
      return a.id - b.id;
    }
  );

  return docs;
}

export async function getDocumentByServiceId(
  serviceId?: Number
): Promise<INqaDocuments[]> {
  const authUser = getUserLoggedIn();
  const { status, data } = await axios.get(
    `/api/document-by-service/${authUser.id}/${serviceId}/user-side`
  );

  if (status == 200 || status == 201) return data;

  return [];
}

export async function requestMentoringSupport(data: IMentorIngPayload) {
  const payload = {
    name: data.name,
    email: data.email,
    phone: data.phone,
    service_name: data.service_name,
    describe_more: data.describe_more,
    preferred_day: data.preferred_day,
    preferred_time: data.preferred_time,
  };

  const response = await axios.post(`/api/mentoring-support`, payload);
  return response.data;
}

export async function fetchMentoringReports() {
  const response = await axios.get(`/api/document-get-mentoring`);
  return response.data;
}

export async function fetchFOMDOcuments(): Promise<IFOMDocument[]> {
  const response = await axios.get(`/api/getDocuments`);
  return response.data;
}

export async function saveServiceDetails(
  data: IServiceUpdate,
  serviceId: number
) {
  const payload = {
    service_type_id: data.service_type_id,
    name: data.name,
    website_link: data.website_link,
    phone: data.phone,
    primary_email: data.primary_email,
    address: data.address,
    city: data.city,
    state: data.state,
    postal: data.postal,
    description: data.description,
    is_archive: data.is_archive,
  };

  const response = await axios.put(
    `/api/services-update/${serviceId}`,
    payload
  );

  return response.data;
}

export async function getServiceDetails(serviceId: number) {
  const response = await axios.get(`services-get/${serviceId}`);
  return response.data;
}

export async function fetchServiceById(id: Number): Promise<IService> {
  return await axios.get(`/api/services-get/${id}`);
}

export async function fetchServices(): Promise<IService[]> {
  const authUser = getUserLoggedIn();

  const response = await axios.get(`/api/center/${authUser.id}/services`);

  return response.data;
}

// export async function fetchUserServices(): Promise<IService[]> {
//   // GET https://dev.holisticqip.com.au/qip_backend/public/api/center/{user_id}/services

//   const response = await axios.get(`/api/services-get-all`);

//   return response.data;
// }

export async function saveServiceBranding(
  serviceId: number,
  data: IServiceBrandingPayload
) {
  const payload = {
    base64_image_logo: data.base64_image_logo,
    logo_name: data.logo_name,
    brand_color: data.brand_color,
    skin: data.skin,
  };

  const response = await axios.put(`/api/save-branding/${serviceId}`, payload);

  return response.data;
}

export async function fetchReports(
  serviceSpecificType: number,
  serviceId?: number
): Promise<IReport[]> {
  const response = await axios.get(
    `/api/reports/${serviceId}/${serviceSpecificType}`
  );

  return response.data;
}

export async function getAuthUser(accessToken: string) {
  // let config = {
  //   headers: {
  //     Authorization: "Bearer " + accessToken,
  //   },
  // };

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_QIP_API_URL}api/user`
    );

    return {
      status: true,
      auth_user: response.data as IAuthUser,
    };
  } catch (error: any) {
    return {
      status: false,
      ...error.toJSON(),
    };
  }
}

export async function getUserDataServices(): Promise<IService[]> {
  let token: any = localStorage.getItem("portalAppToken");

  // let config = {
  //   headers: {
  //     Authorization: "Bearer " + token,
  //   },
  // };

  try {
    const API_URL = process.env.REACT_APP_QIP_API_URL;

    const authUser = getUserLoggedIn();

    // const centers = await axios.get(`/api/center/${authUser.id}/services`);

    // const centersData = await axios.get(`${API_URL}api/user/${authUser.id}`);

    // const subData = await axios.get(
    //   `${API_URL}api/stripe/products/${authUser.id}`
    // );

    const res = await axios.get(
      `${API_URL}api/user/${authUser.id}/centers/subscription`
    );

    const centers = res.data.user_centers.original;
    console.log("centers data", centers);
    const centersData = res.data.user_details.original;

    const subData = res.data.subscription.original;

    return userServicesAudit(centers || [], centersData || [], subData || []);
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function docUpload(payload: DocUpload) {
  const authUser = getUserLoggedIn();
  const response = await axios.post(`/api/user/document/upload`, {
    ...payload,
    user_id: authUser.id,
  });
  return response;
}

export async function docUpdate(payload: DocUpdate) {
  const response = await axios.put(`/api/user/document/update`, payload);
  return response;
}

export async function docDelete(docId: number) {
  const authUser = getUserLoggedIn();
  const response = await axios.delete(
    `/api/user/document/hard/delete/${docId}/${authUser.id}`
  );
  return response;
}
