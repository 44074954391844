import React from "react";
import { Route } from "react-router-dom";
import Layout from "../Layout";
import { Routes } from "../../context/App";

interface RouterProps {
  Component: React.ElementType;
  exact: boolean;
  route:
    | typeof Routes.DASHBOARD
    | typeof Routes.LINKS
    | typeof Routes.MONTHLYFOCUS
    | typeof Routes.NEWSLETTERS
    | typeof Routes.NQADOCUMENTS
    | typeof Routes.NQADOCUMENT
    | typeof Routes.REPORTS
    | typeof Routes.BOOKAMENTORSESSION
    | typeof Routes.SERVICEDETAILS
    | typeof Routes.SEARCHRESULTS;
  path: string;
}

const PrivateRoute: React.FC<RouterProps> = ({
  Component,
  exact,
  route,
  path,
}: RouterProps): JSX.Element => {
  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => {
        return <Layout route={route} Children={Component} {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
