import React, { useEffect, Suspense } from "react";
import {
  BrowserRouter,
  Switch,
  useRouteMatch,
  Route,
  Redirect,
} from "react-router-dom";
import { AppContext, Routes } from "../../context/App";

import DashboardRoute from "../Dashboard";
import MonthlyFocusRoute from "../MontlyFocus";
import NqaDocumentsRoute from "../NqaDocuments";
import NewslettersRoute from "../Newsletters";
import LinksRoute from "../Links";
import ReportsRoute from "../Reports";
import ServiceDetailsRoute from "../ServiceDetails";
import MonthlyViewRoute from "../MonthlyView";
import SearchResultsRoute from "../SearchResults";

import Layout from "../../components/Layout";
import Dashboard from "../../pages/Dashboard";

import { useStoreActions, useStoreState } from "../../hooks";
import { getPortalViewer } from "../../services/auth";

import {
  fetchNewsletters,
  fetchLinks,
  fetchNqaDocuments,
  fetchMentoringReports,
  fetchFOMDOcuments,
  fetchServices,
  getUserDataServices,
} from "../../api/request";

import { fetchReports } from "../../api/request";
import { isEmpty } from "lodash";
import NqaDocumentRoute from "../NqaDocument";

const LoggedInPages: React.FC<any> = (): JSX.Element => {
  const portalViewer = getPortalViewer();

  const setNewsletters = useStoreActions(
    (actions) => actions.appStore.setNewsletters
  );

  const setNqadocuments = useStoreActions(
    (actions) => actions.appStore.setNqadocuments
  );

  const setLinks = useStoreActions((actions) => actions.appStore.setLinks);

  const setMentoringReports = useStoreActions(
    (actions) => actions.appStore.setMentoringReports
  );

  const setFomDocuments = useStoreActions(
    (actions) => actions.appStore.setFomDocuments
  );

  const setServices = useStoreActions(
    (actions) => actions.appStore.setServices
  );

  const setSelectedService = useStoreActions(
    (actions) => actions.appStore.setSelectedService
  );

  const match = useRouteMatch();

  const getData = () => {
    getUserDataServices().then((r) => {
      console.log("services ", r);
      setServices(r);
    });

    fetchNewsletters().then((r) => {
      setNewsletters(r);
    });
    // fetchNqaDocuments().then((r) => {
    //   console.log("nqa ", r);
    //   setNqadocuments(r);
    // });
    fetchLinks().then((r) => {
      console.log("links ", r);
      setLinks(r);
    });
    fetchMentoringReports().then((r) => {
      setMentoringReports(r);
    });
    fetchFOMDOcuments().then((r) => {
      console.log("fom ", r);
      setFomDocuments(r);
    });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <BrowserRouter
      basename={
        isEmpty(portalViewer)
          ? process.env.REACT_APP_BASENAME
          : `viewer-${portalViewer.id}`
      }
    >
      <AppContext.Provider value={{ route: Routes.DASHBOARD }}>
        <Switch>
          <DashboardRoute
            path={`/dashboard`}
            exact={true}
            route={Routes.DASHBOARD}
          />
          <ServiceDetailsRoute
            path={`/edit-service-details`}
            exact={true}
            route={Routes.SERVICEDETAILS}
          />

          <MonthlyFocusRoute
            path={`/monthly-focus`}
            exact={true}
            route={Routes.MONTHLYFOCUS}
          />

          <MonthlyViewRoute
            path={`/monthly-view/:month/:year`}
            exact={true}
            route={Routes.MONTHLYFOCUS}
          />

          <NqaDocumentRoute
            path={`/nqa-document`}
            exact={true}
            route={Routes.NQADOCUMENT}
          />

          <NqaDocumentsRoute
            path={`/nqa-documents`}
            exact={true}
            route={Routes.NQADOCUMENTS}
          />
          <NewslettersRoute
            path={`/newsletters`}
            exact={true}
            route={Routes.NEWSLETTERS}
          />
          <LinksRoute path={`/links`} exact={true} route={Routes.LINKS} />
          <ReportsRoute
            path={`/reports/:type`}
            exact={true}
            route={Routes.REPORTS}
          />
          <SearchResultsRoute
            path={`/search-results/:query`}
            exact={true}
            route={Routes.SEARCHRESULTS}
          />

          <Route
            exact
            path={`/`}
            render={(props) => {
              return (
                <Layout
                  route={Routes.DASHBOARD}
                  Children={Dashboard}
                  {...props}
                />
              );
            }}
          />

          <Route
            path={`${match.path}`}
            render={() => {
              return <Redirect to={`/`} />;
            }}
          />
        </Switch>
      </AppContext.Provider>
    </BrowserRouter>
  );
};

export default LoggedInPages;
