import { IAuthUser } from "../types";

export const setUserToken = (token: string, callback?: any) => {
  localStorage.setItem("portalAppToken", token);
  callback();
};

export const isUserLoggedIn = () => localStorage.getItem("portalAppToken");

export const getUserLoggedIn = (): IAuthUser => {
  const currentAuthUser = localStorage.getItem("authUser") || "{}";
  return JSON.parse(currentAuthUser);
};

export const setPortalViewer = (viewer: IAuthUser) => {
  localStorage.setItem("portalViewer", JSON.stringify(viewer));
};

export const getPortalViewer = (): IAuthUser => {
  const viewer = localStorage.getItem("portalViewer") || "{}";
  return JSON.parse(viewer);
};

export const userLogout = (callback?: any) => {
  localStorage.removeItem("portalAppToken");

  callback();
};
